import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { of } from 'rxjs';
import { ProductsService } from '../../../services/product/products.service';

export const productResolver: ResolveFn<any | null> = (route, state) => {
  const productService = inject(ProductsService);
  const sku = route.paramMap.get('sku');

  if (sku) {
    return productService.get(sku);
  } else {
    return of(null);
  }
};
