import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../primeng.module';
import { MetaTagsService } from '../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../services/product/products.service';
import { ToastService } from '../../services/toast/toast.service';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../../shared/components/breadcrumb/breadcrumb.component';
import { ProductsShopDetailsComponent } from '../../shared/components/products-shop-details/products-shop-details.component';
import {
  ConfigSubCategories,
  ProductsShopComponent,
} from '../../shared/components/products-shop/products-shop.component';
import { Product } from '../../shared/models/product/product';

@Component({
  selector: 'app-products',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    PrimeNGModule,
    ProductsShopComponent,
    ProductsShopDetailsComponent,
  ],
  templateUrl: './products.component.html',
  styleUrl: './products.component.scss',
  providers: [ToastService, MessageService],
})
export class ProductsComponent implements OnInit {
  public subCategory: BreadcrumbData = new BreadcrumbData();
  public configSubCategories?: ConfigSubCategories;
  public urlName: any;
  public product: Product | undefined = new Product();
  public querySearch: any = null;
  public sku: any;
  public model: BreadcrumbData = new BreadcrumbData();

  constructor(
    private activatedRoute: ActivatedRoute,
    private metaServices: MetaTagsService,
    private productService: ProductsService,
    public router: Router,
    private toastService: ToastService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.configPage(url[0]?.path, url[1]?.path, url[3]?.path);
    });
  }

  configPage(sub: string, model: string, sku: string) {
    this.querySearch = this.activatedRoute.snapshot.paramMap.get('querySearch');
    this.sku = this.activatedRoute.snapshot.paramMap.get('sku');
    this.urlName = this.activatedRoute.snapshot.paramMap.get('name');
    this.subCategory = this.setSubCategory(sub);
    this.model = this.setModel(model);

    if (this.querySearch) {
      this.configProducts();
      this.metaServices.configTagPages(
        MetaTagsService.PAGE_PRODUCTS,
        'produtos/' + this.querySearch,
        '',
        '',
        this.querySearch
      );
    } else if (this.sku) {
      this.configProducts();
      this.configProductWithSku();
    }
  }

  configProducts() {
    this.configSubCategories = {
      persianas: undefined,
      subCategorySelected: undefined,
      modelSelected: undefined,
      productsOnOffer: false,
      productsQuerySearch: this.querySearch,
    };
  }

  setSubCategory(path: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageSubCategory(path),
      path,
      id: path?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  setModel(model: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageModels(this.subCategory.id, model),
      path: this.subCategory.path + '/' + model,
      id: model?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  async configProductWithSku() {
    try {
      this.product = this.activatedRoute.snapshot.data['productData'];
      if (this.product) {
        this.metaServices.configTagPages(
          MetaTagsService.PAGE_PRODUCTS,
          this.sku + '/' + this.urlName,
          this.subCategory?.path,
          this.model?.path,
          this.product
        );
      }
    } catch (error: any) {
      this.toastService.error(error);
    }
  }
}
