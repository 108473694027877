import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGModule } from '../../../primeng.module';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { MetaTagsService } from '../../../services/meta-tags/meta-tags.service';
import { ProductsService } from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { AbstractInfoEnum } from '../../models/app/abstract-info-enum';
import { InfoCategory } from '../../models/app/info-category';
import { CategoriesEnum } from '../../models/app/info-enum';
import InfoSubCategory from '../../models/app/info-sub-category';
import { PageResponse } from '../../models/page/page-response';
import { Product } from '../../models/product/product';
import ProductFilter from '../../models/product/product-filter';
import {
  BreadcrumbComponent,
  BreadcrumbData,
} from '../breadcrumb/breadcrumb.component';
import { CarouselProductsComponent } from '../carousel-products/carousel-products.component';
import { MoreInformationComponent } from '../more-information/more-information.component';
import {
  ConfigSubCategories,
  ProductsShopComponent,
} from '../products-shop/products-shop.component';

@Component({
  selector: 'app-subcategories',
  standalone: true,
  imports: [
    BreadcrumbComponent,
    ProductsShopComponent,
    CarouselProductsComponent,
    MoreInformationComponent,
    PrimeNGModule,
  ],
  templateUrl: './models-products.component.html',
  styleUrl: './models-products.component.scss',
  providers: [ToastService, MessageService],
})
export class ModelsProductsComponent {
  public persianas: InfoCategory = new InfoCategory();
  public subCategory: BreadcrumbData = new BreadcrumbData();
  public subCategorySelected: InfoSubCategory | undefined;
  public model: BreadcrumbData = new BreadcrumbData();
  public modelSelected: AbstractInfoEnum | undefined;
  public configSubCategories?: ConfigSubCategories;
  public loadProducts: boolean = true;
  public productsRelated: Product[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private appInfo: AppInfoService,
    private metaServices: MetaTagsService,
    private productsService: ProductsService
  ) {}

  async ngOnInit() {
    this.activatedRoute.url.subscribe((url) => {
      this.configPage(url[0]?.path, url[1]?.path);
    });
  }

  configPage(sub: string, model: string) {
    this.subCategory = this.setSubCategory(sub);
    this.model = this.setModel(model);

    this.metaServices.configTagPages(
      MetaTagsService.PAGE_MODELS,
      model,
      this.subCategory?.path,
      '',
      null
    );
    this.getProductsRelated();
    this.configItens();
  }

  setSubCategory(path: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageSubCategory(path),
      path,
      id: path?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  setModel(model: string): BreadcrumbData {
    return {
      title: this.metaServices.getTitlePageModels(this.subCategory.id, model),
      path: this.subCategory.path + '/' + model,
      id: model?.toUpperCase()?.replaceAll('-', '_'),
    };
  }

  async configItens() {
    this.persianas = await this.appInfo.getCategoryPersianas();

    this.subCategorySelected = this.persianas?.subCategories?.find(
      (sub) => sub.id === this.subCategory.id
    );

    if (this.subCategorySelected) {
      this.modelSelected = this.subCategorySelected?.models?.find(
        (model) => model.id === this.model.id
      );

      this.configSubCategories = {
        persianas: this.persianas,
        subCategorySelected: this.subCategorySelected,
        modelSelected: this.modelSelected,
        productsOnOffer: false,
        productsQuerySearch: undefined,
      };
    }
  }

  async getProductsRelated() {
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.inactive = false;
      filter.perPage = 20;
      filter.withDiscountOrFeatured = true;
      filter.discount = 5;
      filter.categories = [CategoriesEnum.PERSIANAS];
      filter.productPerSquareMeter = true;
      const pageRes: PageResponse | undefined =
        await this.productsService.products(filter);
      if (pageRes) {
        this.productsRelated = pageRes.content || [];
      }
    } catch (error) {
      console.log(error);
    }
  }
}
