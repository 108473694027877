<div
  class="products-shop-main"
  [ngClass]="{
    'products-shop-main-on-offer': productsOnOffer || productsQuerySearch
  }"
>
  <div class="container">
    <div
      class="products-shop-filter"
      [@enterAnimation]
      *ngIf="!isMobile || (isMobile && showFilterMobile)"
    >
      <div class="products-shop-filter-header mobile-visible">
        <p><i class="pi pi-filter"></i>Filtros</p>
        <i
          class="pi pi-times"
          (click)="
            showFilterMobile = !showFilterMobile;
            addOverflowHiddenToBody(showFilterMobile)
          "
        ></i>
      </div>
      <div class="products-shop-filter-content" *ngIf="!productsOnOffer">
        <div class="products-shop-filter-item">
          <div
            class="products-shop-filter-item-content"
            [ngClass]="{ 'category-environment': isCategoryAmbientes() }"
          >
            <p>{{ getTitleModel() }}</p>
            <div
              *ngFor="let model of subCategorySelected?.models"
              class="field-checkbox"
            >
              <p-radioButton
                [inputId]="model.id + '-input'"
                [(ngModel)]="modelSelected"
                name="category"
                [value]="model"
                (onClick)="onClickModel(); addOverflowHiddenToBody(false)"
              ></p-radioButton>
              <label [for]="model.id + '-input'">{{ model.description }}</label>
            </div>
          </div>
        </div>

        <div
          class="products-shop-filter-item"
          *ngFor="let filter of filterProductsShop; let i = index"
        >
          <p *ngIf="filter.visible">{{ filter?.title }}</p>
          <div *ngIf="filter.visible" class="products-shop-filter-item-content">
            <div
              class="field-checkbox"
              *ngFor="let value of filter.values; let subIndex = index"
            >
              <p-radioButton
                [inputId]="value.id + '-' + i + '-' + subIndex"
                [(ngModel)]="filter.filterSelected"
                name="{{ filter.name }}"
                [value]="value"
                (onClick)="
                  onClickFilter(filter); addOverflowHiddenToBody(false)
                "
              ></p-radioButton>
              <label [for]="value?.id + '-' + i + '-' + subIndex">{{
                value?.description
              }}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="products-shop-content">
      <div
        *ngIf="showSkeleton"
        class="products-shop-content-skeleton mobile-invisible"
      >
        <p-skeleton
          *ngFor="let skeleton of [].constructor(8); let i = index"
          [width]="'100%'"
          [height]="'340px'"
        ></p-skeleton>
      </div>

      <div class="products-shop-content-header">
        <p *ngIf="this.products.length > 0">
          {{ pageResponse ? pageResponse.totalElements : 0 }} PRODUTO(S)
        </p>

        <app-products-shop-order
          *ngIf="this.products.length > 0"
          (changed)="changedOrderProducts($event)"
        ></app-products-shop-order>

        <button
          *ngIf="!productsOnOffer"
          class="button-rm mobile-visible"
          aria-label="Abrir tela de filtros"
          type="submit"
          (click)="
            showFilterMobile = !showFilterMobile;
            addOverflowHiddenToBody(showFilterMobile)
          "
        >
          FILTROS
        </button>
      </div>
      <div class="products-shop-content-products">
        <ng-container *ngFor="let product of products">
          <div class="products-shop-item">
            <a routerLink="{{ '/' + product.url }}">
              <img
                src="{{ product.mainPhotoS3Url }}"
                [alt]="'Foto: ' + product.name"
                width="251"
                height="251"
              />
              <div
                *ngIf="product?.featuredBanner || (product?.discount || 0) > 0"
                class="products-shop-item-featured"
              >
                {{
                  product.featuredBanner || product?.discount + "% DE DESCONTO"
                }}
              </div>
              <p class="products-shop-content-name">
                {{ product.name }}
              </p>

              <div class="products-shop-content-value">
                <p *ngIf="(product?.discount || 0) > 0" class="discount">
                  de
                  <span>{{ product.value | customCurrency }}</span> por
                </p>
                <p class="total">
                  {{ product.valueWithDiscount | customCurrency }}
                  {{ product.productPerSquareMeter ? " m²" : "" }}
                  <span>{{ product.valueInterestFree }}</span>
                </p>
                <p *ngIf="(product.discount || 0) <= 0" class="description">
                  ou {{ product.valueWithCashDiscount | customCurrency }} à
                  vista
                </p>
              </div>
              <div
                *ngIf="!product.isPersiana()"
                class="add-to-cart"
                title="Adicionar ao Carrinho"
              >
                <i
                  class="pi pi-shopping-cart"
                  (click)="
                    onClickAddToCart(product);
                    $event.stopPropagation();
                    $event.preventDefault()
                  "
                ></i>
              </div>
              <div class="products-shop-btn-whats">
                <p-button
                  class="prime-button-rm"
                  label="Compre pelo WhatsApp"
                  icon="pi pi-whatsapp"
                  iconPos="left"
                  aria-label="Compre pelo WhatsApp"
                  (click)="
                    onClickBuyByWhatss(product);
                    $event.stopPropagation();
                    $event.preventDefault()
                  "
                ></p-button>
              </div>
            </a>
          </div>
        </ng-container>
      </div>
      <p-paginator
        *ngIf="showPagination()"
        [rows]="16"
        [first]="page * 16"
        [totalRecords]="pageResponse?.totalElements || 0"
        (onPageChange)="onPageChange($event)"
      ></p-paginator>
    </div>
  </div>
</div>
