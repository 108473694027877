<app-breadcrumb
  [subCategoryNew]="subCategory"
  [showSubCategoryNew]="true"
></app-breadcrumb>

<div class="categories-main">
  <div class="container">
    <div class="categories-main-content">
      <a
        href="{{ item.path }}"
        class="categories-main-content-item"
        *ngFor="let item of models"
      >
        {{ item.description }}
      </a>
    </div>
  </div>
</div>
