import { DOCUMENT, NgClass, NgFor, NgIf } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { PrimeNGModule } from '../../../primeng.module';
import { AppInfoService } from '../../../services/app-info/app-info.service';
import { CartService } from '../../../services/cart/cart.service';
import { FreightService } from '../../../services/freight/freight.service';
import { GoogleAnalyticsService } from '../../../services/google/google-analytics.service';
import { PaymentService } from '../../../services/payment/payment.service';
import { ProductsService } from '../../../services/product/products.service';
import { ToastService } from '../../../services/toast/toast.service';
import { WhatsAppService } from '../../../services/whats-app/whats-app.service';
import { InfoCategory } from '../../models/app/info-category';
import { BlindsOptionsEnum } from '../../models/app/info-enum';
import InfoSubCategory from '../../models/app/info-sub-category';
import AttachmentProductLink from '../../models/attachment/attachment-product-link';
import { CartItem } from '../../models/cart/cart';
import CalculateShipping from '../../models/freight/calculate-shipping';
import Carrying from '../../models/freight/carrying';
import Freight from '../../models/freight/freight';
import QuoteList from '../../models/freight/quote-list';
import { PageResponse } from '../../models/page/page-response';
import InformationSection from '../../models/product/information-section';
import { Product } from '../../models/product/product';
import ProductFilter from '../../models/product/product-filter';
import Address from '../../models/user/address';
import { SharedModule } from '../../shared.module';
import { ValueUtils } from '../../utils/value';
import { CarouselProductsComponent } from '../carousel-products/carousel-products.component';
import { FaqComponent } from '../faq/faq.component';
import { FreightCalculateComponent } from '../freight-calculate/freight-calculate.component';
import { GalleriaComponent } from '../galleria/galleria.component';
import { ProductsShopSessionsComponent } from '../products-shop-sessions/products-shop-sessions.component';
import { ProductsShopSimilarComponent } from '../products-shop-similar/products-shop-similar.component';

export class OptionDropdown {
  public name?: string;
  public value?: number;
}

export class OptionsBlinds {
  public controlSideLeft: boolean = false;
  public controlSideRight: boolean = false;
  public driveManual: boolean = false;
  public driveMotor: boolean = false;
  public withPanel: boolean = false;
  public withoutPanel: boolean = false;
}

export class ProductValuesDetails {
  public discount = 0;
  public value = 0;
  public valueWithDiscount = 0;
  public valueInterestFree = '';
  public valueWithCashDiscount = 0;
  public valueInstallment = '';
  public valueInstallmentWithoutJuros = '';
}

@Component({
  selector: 'app-products-shop-details',
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    PrimeNGModule,
    GalleriaComponent,
    ProductsShopSessionsComponent,
    CarouselProductsComponent,
    FaqComponent,
    ProductsShopSimilarComponent,
    SharedModule,
    FreightCalculateComponent,
  ],
  templateUrl: './products-shop-details.component.html',
  styleUrl: './products-shop-details.component.scss',
})
export class ProductsShopDetailsComponent implements OnInit {
  @Input()
  public set product(product: Product | undefined) {
    if (product) {
      this._product = product;
      this.informationsSection = this._product.informationsSection || [];
      this.productValuesDetails = new ProductValuesDetails();
      this.productValuesDetails.discount = this._product.discount || 0;
      this.productValuesDetails.value = this._product.value || 0;
      this.productValuesDetails.valueWithDiscount =
        this._product.valueWithDiscount || 0;
      this.productValuesDetails.valueInterestFree =
        this._product.valueInterestFree || '';
      this.productValuesDetails.valueWithCashDiscount =
        this._product.valueWithCashDiscount || 0;
      this.productValuesDetails.valueInstallment =
        this._product.valueInstallment || '';
      this.productValuesDetails.valueInstallmentWithoutJuros =
        this._product.valueInstallmentWithoutJuros || '';

      this.setMeasures();
      this.getProductMore();
      this.productService.getValueMotorBando();
    }
  }

  constructor(
    private appInfoService: AppInfoService,
    private cartService: CartService,
    private freightService: FreightService,
    private googleAnalyticsService: GoogleAnalyticsService,
    private paymentService: PaymentService,
    private productService: ProductsService,
    private toastService: ToastService,
    private whatsAppService: WhatsAppService,
    @Inject(DOCUMENT) private dom: any
  ) {}

  public persianas?: InfoCategory;
  public errorFreight = '';
  public informationsSection: InformationSection[] = [];
  public loadingFreight = false;
  public optionsBlinds: OptionsBlinds = new OptionsBlinds();
  public optionsWidth?: OptionDropdown[];
  public optionsWidthSelected?: OptionDropdown | null;
  public optionsHeight?: OptionDropdown[];
  public optionsHeightSelected?: OptionDropdown | null;
  public _product?: Product;
  public productsMore: Product[] = [];
  public productsPhotos: AttachmentProductLink[] = [];
  public productValuesDetails: ProductValuesDetails =
    new ProductValuesDetails();
  public quotes: QuoteList | undefined;
  public quantitySelected = 1;
  public similarProduct: Product | undefined;
  public showSimilarProduct = false;
  public validPersianas = false;
  public zipCode?: string;
  public isFullScreen: boolean = false;
  public subCategorySelected: InfoSubCategory | undefined;
  public showSkeleton = true;

  ngOnInit(): void {
    const sectionVideo: InformationSection | undefined =
      this._product?.informationsSection?.find(
        (section) => section.title === 'Vídeo'
      );
    const photos = this._product?.photos || [];

    if (sectionVideo) {
      const { description, id, orderDisplay } = sectionVideo;
      const url = `${description}`;
      const videoIdRegex = /(?:embed\/)([0-9A-Za-z_-]+).*/;
      const videoId = url?.match(videoIdRegex)?.[1];
      const urlAPI = `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;

      photos.push(
        new AttachmentProductLink({
          url,
          id,
          orderDisplay,
          type: 'video',
          urlAPI,
        })
      );
    }

    this.productsPhotos = photos;
    this.googleAnalyticsService.sendEventViewItem(this._product);
    setTimeout(() => {
      this.showSkeleton = false;
    }, 5000);
  }

  async setMeasures() {
    this.persianas = await this.appInfoService.getCategoryPersianas();

    this.subCategorySelected = this.persianas?.subCategories?.find(
      (s) => s.id === this._product?.subCategory
    );

    this.optionsWidth = this.generateOptions(0.95, 2.8);
    this.optionsHeight = this.generateOptions(0.95, 2.8);

    this.optionsHeightSelected = this.optionsHeight[5];
    this.optionsWidthSelected = this.optionsWidth[5];
    this.calculateSquareMeter();
    this.updateValues();
  }

  private generateOptions(
    start: number,
    end: number
  ): Array<{ name: string; value: number }> {
    const options = [];
    const startCm = start * 100;
    const endCm = end * 100;

    for (let i = startCm; i <= endCm; i++) {
      const meters = i / 100;
      options.push({
        name: `${meters.toFixed(2)} m (${i} cm)`,
        value: meters,
      });
    }
    return options;
  }

  async getProductMore() {
    try {
      const filter: ProductFilter = new ProductFilter();
      filter.inactive = false;
      filter.ignoreMainVariation = true;

      filter.perPage = 20;
      filter.productPerSquareMeter = this._product?.productPerSquareMeter;
      filter.subCategories = InfoSubCategory.getEnumFilterSubcategories(
        this._product
      );
      const pageRes: PageResponse | undefined =
        await this.productService.products(filter);
      if ((pageRes?.totalElements || 0) > 0) {
        this.productsMore = pageRes?.content || [];
      }
    } catch (error) {
      console.log(error);
    }
  }

  onClickMoreInf() {
    this.dom
      ?.getElementById('products-shop-sessions-target')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.dom
      ?.getElementById('products-shop-sessions-mobile-target')
      ?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  async onClickAddToCart() {
    try {
      if (!this._product || this.errorFreight) {
        return;
      }

      if (!this.validOptionsPersianas()) {
        return this.toastService.error(
          'Selecione as opções para a sua persiana!'
        );
      }

      if (!this.quotes && !this._product.productPerSquareMeter) {
        return this.toastService.error(
          'Informe um CEP para adicionar seu produto ao carrinho!'
        );
      }

      if (!this._product.enabled) {
        return this.toastService.error(
          `Produto ${this._product.id} não pode ser adicionado ao seu carrinho pois está inativo!`
        );
      }

      if (
        this.quantitySelected > this._product.stockBalance &&
        !this._product.canBeGeneratedWithStockZero
      ) {
        return this.toastService.error(
          `Existe apenas ${this._product.stockBalance} item(s) disponível(is)`
        );
      }

      const cartItem: CartItem = {
        productId: this._product.id,
        amount: this.quantitySelected,
        product: this._product,
      };

      if (this._product?.productPerSquareMeter) {
        cartItem.customHeight = this.optionsHeightSelected?.value;
        cartItem.customWidth = this.optionsWidthSelected?.value;
      }

      if (this._product?.isPersiana()) {
        cartItem.controlSide = this.optionsBlinds.controlSideLeft
          ? BlindsOptionsEnum.CONTROL_SIDE_LEFT
          : BlindsOptionsEnum.CONTROL_SIDE_RIGHT;
        cartItem.drive = this.optionsBlinds.driveManual
          ? BlindsOptionsEnum.DRIVE_MANUAL
          : BlindsOptionsEnum.DRIVE_MOTOR;
        cartItem.typeFixing = BlindsOptionsEnum.TYPE_FIXING_WALL;

        if (!this._product?.isPersianaRomana()) {
          cartItem.panel = this.optionsBlinds.withPanel
            ? BlindsOptionsEnum.WITH_PANEL
            : BlindsOptionsEnum.WITHOUT_PANEL;
        }
      }

      try {
        await this.cartService.addItem(cartItem, true, true);
        this.toastService.success(
          'Produto adicionado ao carrinho com sucesso!',
          2000
        );
      } catch (error: any) {
        this.toastService.error(error);
      }
    } catch (err) {
      this.toastService.error(
        'Ocorreu um erro ao tentar adicionar seu produto ao carrinho!'
      );
    }
  }

  validOptionsPersianas() {
    this.validPersianas = true;
    let valid = true;
    if (this._product?.isPersiana()) {
      const hasSide =
        this.optionsBlinds.controlSideLeft ||
        this.optionsBlinds.controlSideRight;
      const hasDrive =
        this.optionsBlinds.driveManual || this.optionsBlinds.driveMotor;
      const hasPanel =
        this.optionsBlinds.withPanel || this.optionsBlinds.withoutPanel;

      valid = hasSide && hasDrive;

      if (valid && !this._product?.isPersianaRomana()) {
        valid = hasPanel;
      }
    }
    return valid;
  }

  onZipCodeError() {
    this.errorFreight = 'Por favor, digite um CEP válido!';
  }

  async onChangeZipCode() {
    this.quotes = undefined;
    this.zipCode = '';
  }

  async onAddressFound(address: Address) {
    this.errorFreight = '';
    if (address?.zipCode) {
      this.loadingFreight = true;
      setTimeout(async () => {
        this.zipCode = address?.zipCode || '';
        await this.calculateFreight(address?.zipCode || '');
      }, 500);
    } else {
      this.zipCode = '';
      this.toastService.error('CEP não localizado!5');
    }
  }

  async calculateFreight(zipCode: string | undefined) {
    this.quotes = undefined;
    try {
      const calculate: CalculateShipping = new CalculateShipping();
      calculate.cep = zipCode;
      let item: any = {};
      item.amount = this.quantitySelected || 1;
      item.productId = this._product?.id || 0;

      if (this._product?.productPerSquareMeter) {
        item.customHeight = this.optionsHeightSelected?.value;
        item.customWidth = this.optionsWidthSelected?.value;
      }

      calculate.itens = [item];
      const quotes: QuoteList = await this.freightService.calculateShipping(
        calculate
      );

      this.errorFreight = '';
      if ((quotes?.carriers?.length || 0) > 0) {
        if ((this._product?.daysOnlyOrder || 0) > 0) {
          quotes?.carriers?.forEach((carrier) => {
            if (carrier?.optionFreight === Freight.STORE_PICKUP) {
              carrier.deliveryTime =
                (carrier.deliveryTime || 0) +
                (this._product?.daysOnlyOrder || 0);
            }
          });
        }
        this.quotes = quotes;
      } else {
        if ((this._product?.similarProduct || 0) > 0) {
          await this.getSimilarProduct(this._product?.similarProduct || 0);
          this.errorFreight =
            'Para cotar esse frete com 6 metros, solicite via whatsapp!!!';
        } else {
          this.errorFreight = 'Entrega não disponível para sua região!';
        }
      }
    } catch (err) {
      this.errorFreight = 'Entrega não disponível para sua região!';
    } finally {
      this.loadingFreight = false;
    }
  }

  async getSimilarProduct(id: number) {
    this.similarProduct = undefined;
    try {
      this.similarProduct = await this.productService.get(id.toString());
      this.showSimilarProduct = true;
    } catch (err) {
      console.error(err);
    }
  }

  onClickBuyByWhatss() {
    if (this._product) {
      this.whatsAppService.setOpenWhatsAppProduct(this._product);
    }
  }

  onClickDrive(driveManual: boolean, driveMotor: boolean) {
    const valueMotor = this.productService.getValueMotor();
    if (driveManual) {
      if (this.optionsBlinds.driveManual) {
        this.optionsBlinds.driveManual = false;
      } else {
        this.optionsBlinds.driveManual = true;
        if (this.optionsBlinds.driveMotor) {
          this.optionsBlinds.driveMotor = false;
          this.productValuesDetails.value =
            (this.productValuesDetails.value || 0) - valueMotor;
          this.updateValues();
        }
      }
    }

    if (driveMotor) {
      if (this.optionsBlinds.driveMotor) {
        this.optionsBlinds.driveMotor = false;
        this.productValuesDetails.value =
          (this.productValuesDetails.value || 0) - valueMotor;
        this.updateValues();
      } else {
        this.optionsBlinds.driveMotor = true;
        this.optionsBlinds.driveManual = false;
        this.productValuesDetails.value =
          (this.productValuesDetails.value || 0) + valueMotor;
        this.updateValues();
      }
    }
  }

  onClickPanel(withoutPanel: boolean, withPanel: boolean) {
    const valueBando = this.productService.getValueTotalBando(
      this.optionsWidthSelected?.value || 0
    );
    if (withoutPanel) {
      if (this.optionsBlinds.withoutPanel) {
        this.optionsBlinds.withoutPanel = false;
      } else {
        this.optionsBlinds.withoutPanel = true;
        if (this.optionsBlinds.withPanel) {
          this.optionsBlinds.withPanel = false;
          this.productValuesDetails.value =
            (this.productValuesDetails.value || 0) - valueBando;
          this.updateValues();
        }
      }
    }

    if (withPanel) {
      if (this.optionsBlinds.withPanel) {
        this.optionsBlinds.withPanel = false;
        this.productValuesDetails.value =
          (this.productValuesDetails.value || 0) - valueBando;
        this.updateValues();
      } else {
        this.optionsBlinds.withPanel = true;
        this.optionsBlinds.withoutPanel = false;
        this.productValuesDetails.value =
          (this.productValuesDetails.value || 0) + valueBando;
        this.updateValues();
      }
    }
  }

  updateValues() {
    this.productValuesDetails.valueWithDiscount = this.getValueWithDiscount();
    this.productValuesDetails.valueInterestFree =
      this.installmentFormatInterestFree();
    this.productValuesDetails.valueWithCashDiscount =
      this.getValueWithCashDiscount();
    this.productValuesDetails.valueInstallment = this.getInstallmentValue();
    this.productValuesDetails.valueInstallmentWithoutJuros =
      this.getInstallmentWithoutJurosValue();
  }

  getValueWithDiscount(): number {
    const discount = this.productValuesDetails.discount || 0;
    let value = this.productValuesDetails.value || 0;
    if (discount > 0) {
      value = value - (value * discount) / 100;
    }
    return value;
  }

  installmentFormatInterestFree(): string {
    const value = this.productValuesDetails.value || 0;
    const installments = this.paymentService.getTotalInstallments(value, true);
    const valueInstallmentFormat = ValueUtils.currency(value / installments);
    return `${installments}x de ${valueInstallmentFormat} sem juros`;
  }

  getValueWithCashDiscount(): number {
    let value = this.productValuesDetails.value || 0;
    let cashDiscount = environment.cashDiscount || 0;
    if (cashDiscount > 0) {
      value = value - (value * cashDiscount) / 100;
    }
    return value;
  }

  getInstallmentValue() {
    const installments = this.paymentService.getInstallments(
      this.productValuesDetails.value || 0
    );
    const valueFormat = ValueUtils.currency(
      installments[installments.length - 1]
    );
    return `${installments.length}x de ${valueFormat}`;
  }

  getInstallmentWithoutJurosValue() {
    const value = this.productValuesDetails.value || 0;
    const installments = this.paymentService.getTotalInstallments(value, true);
    const valueInstallmentFormat = ValueUtils.currency(value / installments);
    return `${installments}x de ${valueInstallmentFormat} sem juros`;
  }

  get valueMotor(): number {
    return this.productService.getValueMotor() || 0;
  }

  getValueTotalBando(): number {
    return this.productService.getValueTotalBando(
      this.optionsWidthSelected?.value || 0
    );
  }

  onChangeOptionsWidth(event: any) {
    if (event?.value) {
      this.optionsWidthSelected = Object.assign(
        new OptionDropdown(),
        event?.value
      );
      this.calculateSquareMeter();
      this.updateValues();
      this.calculateFreight(this.zipCode);
    }
  }

  onChangeOptionsHeight(event: any) {
    if (event?.value) {
      this.optionsHeightSelected = Object.assign(
        new OptionDropdown(),
        event?.value
      );

      this.calculateSquareMeter();
      this.updateValues();
      this.calculateFreight(this.zipCode);
    }
  }

  calculateSquareMeter() {
    if (this._product?.productPerSquareMeter) {
      const value =
        (this.optionsHeightSelected?.value || 0) *
        (this.optionsWidthSelected?.value || 0);
      this.productValuesDetails.value = value * (this._product?.value || 0);
    }
  }

  onSetQuantitySelected(event: any) {
    const quantitySelected = Number(event?.target?.value || 1);
    if (
      quantitySelected > (this._product?.stockBalance || 0) &&
      !this._product?.canBeGeneratedWithStockZero
    ) {
      this.quantitySelected = this._product?.stockBalance || 0;
      event.target.value = this.quantitySelected;
      this.calculateFreight(this.zipCode);
      this.toastService.error(
        `Quantidade informada não disponível. Existe apenas ${this._product?.stockBalance} item(s) disponível(is)`
      );
    } else {
      this.quantitySelected = quantitySelected;
      this.calculateFreight(this.zipCode);
    }
  }

  getCarrier(index: number): Carrying {
    const carrier = this.quotes?.carriers
      ? this.quotes?.carriers[index]
      : new Carrying();
    return carrier;
  }
}
