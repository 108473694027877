<p-toast position="top-center" [baseZIndex]="9999"></p-toast>
<app-breadcrumb [path]="'Carrinho'" [showTitle]="true"></app-breadcrumb>

<div class="cart-main">
  <div class="container">
    <p-progressSpinner
      *ngIf="loadingCart"
      strokeWidth="3"
      aria-label="Buscando informações dos seus pedidos"
    ></p-progressSpinner>
    <app-has-no-itens
      *ngIf="!hasCart() && !loadingCart"
      [title]="'seu carrinho está vazio!'"
      [labelButton]="'comprar produtos'"
      [route]="'/coberturas-em-policarbonato/policarbonato-alveolar'"
    ></app-has-no-itens>
    <div class="cart-main-content" *ngIf="hasCart() && !loadingCart">
      <div class="cart-main-content-products">
        <p-table
          class="mobile-invisible"
          [value]="cart.items || []"
          [tableStyle]="{ 'min-width': '60rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>PRODUTO</th>
              <th>PREÇO</th>
              <th style="width: 140px">QUANTIDADE</th>
              <th>TOTAL</th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>
                <a href="{{ item?.product?.url }}" target="_blank">
                  <div>
                    <span>
                      {{ item?.product?.name }}
                      <span *ngIf="item?.product?.productPerSquareMeter">
                        {{
                          item?.product?.productPerSquareMeter
                            ? " - (" +
                              item?.customWidth?.toFixed(2) +
                              " m x " +
                              item?.customHeight?.toFixed(2) +
                              " m)"
                            : ""
                        }}
                      </span>
                    </span>
                    <small
                      ><span>Marca: </span
                      >{{ item?.product?.brandDescription || "" }}
                      <span> - Código: </span>{{ item?.product?.id }}
                    </small>
                    <small *ngIf="item?.product?.isPersiana()" class="is-blinds"
                      >{{ item?.controlSideDescription }} -
                      {{ item?.driveDescription }}
                      {{
                        item?.product?.isPersianaRomana()
                          ? ""
                          : " - " + item?.panelDescription
                      }}</small
                    >
                  </div>
                  <img
                    [src]="
                      item?.product?.mainPhotoS3Url ||
                      item?.product?.photos?.slice()?.shift()?.url ||
                      ''
                    "
                    [alt]="item?.product?.name"
                    width="80"
                    height="80"
                  />
                </a>
              </td>
              <td>
                <p
                  [ngClass]="{
                    'with-discount': item.product?.discount > 0
                  }"
                >
                  {{ getValueItem(item, false) | customCurrency }}
                </p>
                <p *ngIf="item.product?.discount > 0">
                  {{ getValueItem(item, true) | customCurrency }}
                </p>
              </td>
              <td style="width: 140px">
                <input
                  pInputText
                  type="number"
                  [(ngModel)]="item.amount"
                  (change)="setQuantity(item)"
                  name="amount"
                  aria-label="Alterar quantidade do produto"
                  [min]="0"
                  [max]="100"
                />
              </td>
              <td>
                <p
                  [ngClass]="{
                    'with-discount': item.product?.discount > 0
                  }"
                >
                  {{
                    getValueItem(item, false) * item?.amount | customCurrency
                  }}
                </p>
                <p *ngIf="item.product?.discount > 0">
                  {{ getValueItem(item, true) * item?.amount | customCurrency }}
                </p>
              </td>
              <td>
                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-minus"
                  class="p-button-danger"
                  aria-label="Remover item selecionado do carrinhho!"
                  pTooltip="Remover item selecionado do carrinho!"
                  tooltipPosition="bottom"
                  (click)="removeItem(item)"
                  [autoHide]="false"
                ></button>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="cart-main-content-products-mobile mobile-visible">
          <h2>PRODUTOS</h2>

          <div
            *ngFor="let item of cart.items"
            class="cart-main-content-products-mobile-itens"
          >
            <div class="cart-main-content-products-mobile-item">
              <a href="{{ item?.product?.url }}" target="_blank">
                <img
                  [src]="
                    item?.product?.mainPhotoS3Url ||
                    item?.product?.photos?.slice()?.shift()?.url ||
                    ''
                  "
                  [alt]="item?.product?.name"
                  width="80"
                  height="80"
                />
                <div class="mobile-item-name">
                  <p>
                    {{ item?.product?.name }} -
                    {{ item?.product?.brandDescription || "" }} -
                    {{ item?.product?.id }}
                  </p>
                  <small *ngIf="item?.product?.isPersiana()">
                    {{ item?.controlSideDescription }}
                    - {{ item?.driveDescription }}
                    {{
                      item?.product?.isPersianaRomana()
                        ? ""
                        : " - " + item?.panelDescription
                    }}
                  </small>
                </div>
              </a>
              <div class="mobile-item-value">
                <p *ngIf="(item.product?.discount || 0) <= 0">
                  {{ item?.product?.value | customCurrency }}
                </p>

                <p
                  *ngIf="(item.product?.discount || 0) > 0"
                  [ngClass]="{
                    'with-discount': (item.product?.discount || 0) > 0
                  }"
                >
                  <span>{{ item?.product?.value | customCurrency }}</span
                  >{{ item?.product?.valueWithDiscount | customCurrency }}
                </p>
                <input
                  pInputText
                  type="number"
                  [(ngModel)]="item.amount"
                  (change)="setQuantity(item)"
                  name="amount"
                  aria-label="Alterar quantidade do produto"
                  [min]="0"
                  [max]="100"
                />
                <p *ngIf="(item.product?.discount || 0) <= 0">
                  {{
                    (item?.product?.value || 0) * (item?.amount || 0)
                      | customCurrency
                  }}
                </p>

                <p
                  *ngIf="(item.product?.discount || 0) > 0"
                  [ngClass]="{
                    'with-discount': (item.product?.discount || 0) > 0
                  }"
                >
                  <span>
                    {{
                      (item?.product?.value || 0) * (item?.amount || 0)
                        | customCurrency
                    }}</span
                  >{{
                    (item?.product?.valueWithDiscount || 0) *
                      (item?.amount || 0) | customCurrency
                  }}
                </p>

                <button
                  type="button"
                  pButton
                  pRipple
                  icon="pi pi-minus"
                  class="p-button-danger"
                  aria-label="Remover item selecionado do carrinhho!"
                  pTooltip="Remover item selecionado do carrinho!"
                  tooltipPosition="bottom"
                  (click)="removeItem(item)"
                  [autoHide]="false"
                ></button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="cart-main-content-products-admin"
          *ngIf="user && user?.isAdmin && hasCart()"
        >
          <button
            class="button-rm"
            aria-label="Gerar orçamento para cliente"
            (click)="showBudget = true"
            title="Gerar orçamento para cliente"
          >
            GERAR ORÇAMENTO
          </button>
          <button
            class="button-rm"
            aria-label="Remover o carrinho"
            (click)="onClickDelete()"
            title="Remover o carrinho"
          >
            LIMPAR
          </button>
        </div>
      </div>
      <div class="cart-main-content-resume">
        <div class="cart-main-content-resume-freight">
          <h2>FRETE</h2>
          <div class="cart-main-content-resume-freight-zip">
            <app-zip-code-input
              placeholder="Seu CEP"
              (changed)="changeZipCode()"
              (addressFound)="addressFound($event)"
            ></app-zip-code-input>
          </div>
          <app-freight-quotes
            [loadingFreight]="loadingFreight"
            [quotes]="quotes"
            [errorFreight]="freightError"
            [freightSelected]="freightSelected"
            (changeFreightSelected)="setFreightSelected($event)"
          ></app-freight-quotes>
        </div>
        <div class="cart-main-content-resume-order">
          <h2>RESUMO DO PEDIDO</h2>
          <div class="order-subtotal">
            <p>Subtotal</p>
            <p>{{ cart.valueSubtotal | customCurrency }}</p>
          </div>
          <div *ngIf="cart.haveProductDiscount" class="order-subtotal discount">
            <p>Desconto do produto à vista</p>
            <p>-{{ cart.valueDiscount | customCurrency }}</p>
          </div>
          <div
            *ngIf="(freightSelected?.freight?.priceFreight || 0) > 0"
            class="order-subtotal last"
          >
            <p>Frete</p>
            <p>
              +{{
                freightSelected?.freight?.priceFreight || 0 | customCurrency
              }}
            </p>
          </div>
          <div class="order-total">
            <p>Total</p>
            <div>
              <p>{{ cart.valueTotal || 0 | customCurrency }} à vista</p>
              <p>
                {{
                  (cart.valueSubtotal || 0) +
                    (freightSelected?.freight?.priceFreight || 0)
                    | customCurrency
                }}
                em 4x sem juros
              </p>

              <p>ou em até 12x vezes</p>
            </div>
          </div>

          <div class="order-checkout">
            <p>cupons e outras opções de pagamentos na próxima etapa</p>
            <div class="mobile-invisible">
              <a href="/pronta-entrega/blackout">
                <button
                  title="Continuar comprando"
                  class="button-rm"
                  aria-label="Continuar comprando"
                >
                  CONTINUAR COMPRANDO
                </button></a
              ><button
                (click)="onClickCheckout()"
                title="Finalizar sua compra"
                class="button-rm"
                aria-label="Finalizar sua compra"
              >
                FINALIZAR SUA COMPRA
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="cart-footer-mobile mobile-visible">
  <a href="/coberturas-em-policarbonato/policarbonato-alveolar">
    <button
      title="Continuar comprando"
      class="button-rm"
      aria-label="Continuar comprando"
    >
      CONTINUAR COMPRANDO
    </button></a
  ><button
    (click)="onClickCheckout()"
    title="Finalizar sua compra"
    class="button-rm"
    aria-label="Finalizar sua compra"
  >
    FINALIZAR SUA COMPRA
  </button>
</div>

<p-dialog
  header="Preencha os dados"
  [visible]="showBudget"
  [modal]="true"
  [style]="{ width: '25vw', height: '43vh' }"
  [draggable]="false"
  [resizable]="false"
  (onHide)="showBudget = false"
  [(visible)]="showBudget"
>
  <form action="">
    <input
      type="text"
      placeholder="Informe o nome"
      required
      name="name"
      pInputText
      [(ngModel)]="budget.name"
      [ngClass]="{
        'error-input': budgetError.errorName
      }"
    />
    <input
      type="text"
      placeholder="Informe o e-mail"
      required
      pInputText
      name="email"
      [(ngModel)]="budget.email"
      [ngClass]="{
        'error-input': budgetError.errorEmail
      }"
    />
    <p-inputMask
      mask="(99) 99999-9999"
      name="telefone"
      [(ngModel)]="budget.phone"
      placeholder="Informe o telefone com DDD"
      [ngClass]="{
        'error-input-mask': budgetError.errorPhone
      }"
    ></p-inputMask>

    <p-inputMask
      name="zipCode"
      mask="99999-999"
      type="text"
      [(ngModel)]="zipCode"
      [disabled]="true"
      [ngClass]="{
        'error-input-mask': !zipCode
      }"
    ></p-inputMask>

    <button
      class="button-rm"
      aria-label="Gerar orçamento"
      type="submit"
      (click)="onClickBtCreateBudget()"
    >
      GERAR ORÇAMENTO
    </button>
  </form>
</p-dialog>
