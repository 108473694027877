<app-breadcrumb
  [subCategoryNew]="subCategory"
  [model]="model"
  [showModel]="true"
></app-breadcrumb>

<div class="subcategories-main">
  <app-products-shop
    [configSubCategories]="configSubCategories"
  ></app-products-shop>
</div>

<app-more-information
  [subCategory]="subCategorySelected"
></app-more-information>

<div class="products-related">
  <div class="container">
    <h1 class="section-title">Outlet</h1>
    <app-carousel-products [products]="productsRelated"></app-carousel-products>
  </div>
</div>
