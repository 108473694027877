import { inject, Injectable, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageConstants } from '../../shared/models/storage/storage-constants';
import User from '../../shared/models/user/user';
import { CartService } from '../cart/cart.service';
import { HttpService } from '../http/http.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { SessionStorageService } from '../session-storage/session-storage.service';
import { TokenService } from '../token/token.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userData$ = new BehaviorSubject<any>('');

  private httpService = inject(HttpService);
  private localStorageService = inject(LocalStorageService);
  private sessionStorageService = inject(SessionStorageService);
  private tokenService = inject(TokenService);

  private cartService: CartService | undefined;
  private userService: UserService | undefined;

  constructor(private injector: Injector) {}

  getUserData() {
    this.localStorageService.get(StorageConstants.AMBIENTA_USER).then((res) => {
      this.userData$.next(res);
    });
  }

  private getCartService(): CartService {
    if (!this.cartService) {
      this.cartService = this.injector.get(CartService);
    }
    return this.cartService;
  }

  private getUserService(): UserService {
    if (!this.userService) {
      this.userService = this.injector.get(UserService);
    }
    return this.userService;
  }

  public async login(user: User): Promise<User> {
    return new Promise((resolve, reject) => {
      this.httpService.login(user).subscribe({
        next: async (res: any) => {
          if (res) {
            const requestBudget = await this.sessionStorageService.get(
              StorageConstants.AMBIENTA_REQUEST_BUDGET
            );
            this.localStorageService.set(
              StorageConstants.AMBIENTA_TOKEN,
              res.access_token
            );
            this.tokenService.token$.next(res.access_token);

            try {
              const userLogged = await this.logged();
              this.localStorageService.set(
                StorageConstants.AMBIENTA_USER,
                userLogged
              );
              this.userData$.next(userLogged);

              const cartService = this.getCartService();
              const userService = this.getUserService();

              let hasNewProducts = false;
              try {
                hasNewProducts = await cartService.checkCartItems();
              } catch (error) {
                console.log(error);
              }

              if (requestBudget) {
                if (hasNewProducts) {
                  cartService.goToCart();
                } else {
                  userService.goToPayment();
                }
              } else {
                userService.goToCustomerOrders();
              }
              resolve(res);
            } catch (error) {
              console.log(error);
            }
          }
        },
        error: (error: any) => {
          let msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao realizar seu login!';
          if (msg === 'invalid_grant') {
            msg = 'Usuário ou senha inválidos!';
          }
          reject(msg);
        },
      });
    });
  }

  public logged(): Promise<User> {
    const user = new User();
    return new Promise((resolve, reject) => {
      this.httpService.get('user/logged/').subscribe({
        next: (res: any) => {
          if (res) {
            user.fromJson(res);
            resolve(user);
          }
        },
        error: (error: any) => {
          const msg =
            error?.error?.message ||
            error?.error?.error ||
            'Ocorreu um erro ao buscar o usuário logado!';
          reject(msg);
        },
      });
    });
  }

  public async refreshUser(user: User) {
    try {
      this.localStorageService.set(StorageConstants.AMBIENTA_USER, user);
      this.userData$.next(user);
    } catch (error) {
      console.log(error);
    }
  }

  logout() {
    this.localStorageService.logout().then(() => {
      this.userData$.next('');
      this.tokenService.token$.next('');
      this.getCartService().updateCartState(true, false);
      this.getUserService().goToLogin();
    });
  }
}
